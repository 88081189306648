import React, { FC, useCallback } from 'react';

import useScreenRecognition from 'hooks/useScreenRecognition';
import Button from 'components/common/Button';

import IconCustom from 'components/common/IconCustom';

import { isBrowser } from 'utils/browser';
import { ISocialLinksProps } from './model';
import { shareLinks, windowParams } from './constants';
import './SocialLinks.scss';
import './SocialLinksOverride.scss';

const SocialLinks: FC<ISocialLinksProps> = ({ links, socialPostTitle }) => {
  const { isMobile } = useScreenRecognition();
  const defaultLink = isBrowser() ? window.location.href : '/';
  const openPopupWindow = useCallback(
    (shareLinkUrl: string) => () => {
      isBrowser() && window.open(shareLinkUrl, 'NewWindow', windowParams(isMobile));
    },
    [isMobile]
  );

  return links?.length > 0 ? (
    <ul className="social-links" data-testid="social-links">
      {links.map(({ properties: { ariaLabel, show, name } }) => {
        const shareLinkName = name[0].toLowerCase();
        const shareLinkUrl = shareLinks[shareLinkName](defaultLink, socialPostTitle);

        return shareLinks[shareLinkName] && show ? (
          <li key={shareLinkName}>
            <Button
              aria-label={ariaLabel}
              role="presentation"
              variant="icon"
              onClick={openPopupWindow(shareLinkUrl)}
            >
              <IconCustom icon={shareLinkName} />
            </Button>
          </li>
        ) : null;
      })}
    </ul>
  ) : null;
};
export default SocialLinks;
